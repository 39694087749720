import React from "react";
import { useNavigate } from "react-router-dom";

const PricingPage: React.FC = () => {
  const navigate = useNavigate();

  const pricingOptions = [
    {
      title: "Essential",
      description: "For students aspiring to be a topper",
      price: 2999,
      period: "/3 months",
      discount: "Limited time 50% discount",
      features: [
        "Ask Doubts from mentor",
        "Tips n Tricks to solve Tricky Qs",
        "1:1 mentorship",
        "Live JEE paper solving by IITians",
        "Test discussion with mentor",
        "Ask Doubts from mentor on chat",
        "IITian Mentor Sessions on Study Transformation",
      ],
    },
    {
      title: "Elite",
      description: "For very serious IIT JEE Aspirants",
      price: 4999,
      period: "/3 months",
      discount: "Most Popular",
      features: [
        "Ask Doubts from IITian mentor",
        "Tips n Tricks to solve Tricky Qs",
        "1:1 mentorship",
        "Live JEE paper solving by IITians",
        "Test discussion with IITian mentor",
        "Ask Doubts from mentor on chat",
        "Access to Private Community & Study Groups",
        "IITian Mentor Sessions on Study Transformation",
      ],
    },
    {
      title: "Ultimate",
      description: "For the Pros",
      price: 4999,
      period: "/3 months",
      discount: "Premium services at the best price",
      features: [
        "Ask Doubts from mentor",
        "Tips n Tricks to solve Tricky Qs",
        "1:1 mentorship",
        "Live JEE paper solving by IITians",
        "Test discussion with mentor",
        "Ask Doubts from mentor on Video call & chat",
        "Access to Private Community & Study Groups",
        "IITian Mentor Sessions on Study Transformation",
      ],
    },
    {
      title: "Supreme",
      description: "For the Enthusiasts",
      price: 6999,
      period: "/3 months",
      discount: "Best Value",
      features: [
        "Ask Doubts from IITian mentor",
        "Tips n Tricks to solve Tricky Qs",
        "1:1 mentorship",
        "Live JEE paper solving by IITians",
        "Test discussion with IITian mentor",
        "Ask Doubts from mentor on Video call & chat",
        "IITian Mentor Sessions on Study Transformation",
        "Access to Private Community & Study Groups",
        "IITian Mentor Sessions on IIT JEE Preparation",
      ],
    },
  ];

  const additionalCourses = [
    { name: "Test Analysis with Mentor (weekly) ", price: 299 },
    { name: "Doubt Solving (weekly)", price: 499 },
    { name: "Doubt Solving (Half month)", price: 799 },
    { name: "Doubt Solving (Monthly)", price: 849 },
    { name: "Physics how to tackle question (1:1-1 day) ", price: 199 },
    { name: "1:1 Mentorship (Weekly)", price: 299 },
    { name: "1:1 Mentorship (Monthly)", price: 499 },
    { name: "January physics booster (1:1 for 1 week) ", price: 499 },
    { name: "Tips to Master Particular Subject (Monthly)", price: 949 },
    { name: "Tricks to Solve Qs in Papers (3 Sessions)", price: 109 },
    { name: "Test Analysis with Mentor (Weekly)", price: 299 },
    { name: "A Quick Discussion and Planning Meeting (1 Hour)", price: 99 },
  ];

  const handleBookNow2 = () => {
    // Redirect to Razorpay payment link
    window.location.href = "https://razorpay.me/@crackiit";
  };

  return (
    <div className="relative">
      {/* Background Video */}
      <div className="fixed top-0 left-0 w-full h-full -z-10">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="/herosection.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="container mx-auto px-4 py-16 relative z-20">
        <h4 className="pricing-title text-4xl font-bold text-center mb-12">
          Come...Let's Polish Your Journey Together!!!
        </h4>
        <h2 className="pricing-title text-4xl font-bold text-center mb-12">
          Courses
        </h2>
        <h2>
          You will receive the course and next steps on
          your email after payment
        </h2>

        {/* Small Cards for Additional Courses */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {additionalCourses.map((course, index) => (
            <div
              key={index}
              className="relative bg-gradient-to-br from-purple-900 to-purple-600 p-4 rounded-3xl shadow-lg text-white"
              style={{
                borderImage: "linear-gradient(to right, #8a2be2, #4b0082) 1",
                borderWidth: "4px",
                borderStyle: "solid",
              }}
            >
              <h3 className="text-xl font-bold mb-2 text-purple-300">
                {course.name}
              </h3>
              
              <button
                onClick={handleBookNow2}
                className="block w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-center transition duration-300"
              >
                Buy Now for ₹{course.price}
              </button>
            </div>
          ))}
        </div>

        {/* Cards Container */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {pricingOptions.map((option, index) => (
            <div
              key={index}
              className="relative bg-gradient-to-br from-purple-900 to-purple-600 p-8 rounded-3xl shadow-lg text-white"
              style={{
                borderImage: "linear-gradient(to right, #8a2be2, #4b0082) 1",
                borderWidth: "6px",
                borderStyle: "solid",
              }}
            >
              <h3 className="text-2xl font-bold mb-1 text-purple-300">
                {option.title}
              </h3>
              <p className="text-sm mb-4">{option.description}</p>

              <div className="flex justify-between items-center mb-4">
                <span className="text-3xl font-bold">₹{option.price}</span>
                <span className="text-sm">{option.period}</span>
              </div>
              <div
                className="bg-gradient-to-r from-indigo-400 to-purple-600 text-indigo-50 text-sm font-semibold py-1 px-3 rounded-full inline-block mb-4"
                style={{ display: option.discount ? "inline-block" : "none" }}
              >
                {option.discount}
              </div>
              <ul className="space-y-2 mb-6 text-sm">
                {option.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <svg
                      className="w-5 h-5 text-green-500 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a 1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
              <button
                onClick={handleBookNow2}
                className="block w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-center transition duration-300"
              >
                Start now for ₹{option.price}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
