import React, { useState } from "react";
import axios from "axios";

const StudyBuddy: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    class: "",
    mockTestScore: "",
    coachingTestScore: "",
    email: "",
    discordProfile: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://crackiit-env.eba-vwz5sgzx.ap-south-1.elasticbeanstalk.com:8080/api/studybuddy", formData);
      if (response.status === 200) {
        alert("Profile created successfully!");
        setFormData({
          name: "",
          phoneNo: "",
          class: "",
          mockTestScore: "",
          coachingTestScore: "",
          email: "",
          discordProfile: "",
        });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="relative">
      {/* Video background */}
      <div className="fixed top-0 left-0 w-full h-full -z-10">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
        >
          <source src="/herosection.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Contact Us Section */}
      <div className="max-w-lg mx-auto text-center text-white mb-8 mt-8">
        <p className="text-xl font-bold">Contact Us</p>
        <p className="text-lg">Wanna connect as mentor or have any questions, feel free to reach out to us at email: </p>
        <a
          href="mailto:hello@69kelvin.com"
          className="text-purple-600 underline text-lg"
        >
          hello@69kelvin.com
        </a>
      </div>

      {/* Form Section */}
      
    </div>
  );
};

export default StudyBuddy;
