import React from "react";

const Masterfeature: React.FC = () => {
  const handleBookNow = () => {
    // Redirect to Razorpay payment link
    window.location.href = "https://razorpay.me/@crackiit";
  };

  return (
    <div className="relative min-h-screen  text-white">
      {/* Background Video */}
      <div className="fixed top-0 left-0 w-full h-full -z-10">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="/herosection.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="container mx-auto px-4 py-16 relative z-20 flex justify-center items-center min-h-screen">
        {/* Single Card */}
        <div
          className="relative bg-gradient-to-br from-purple-800 to-purple-600 p-8 rounded-3xl shadow-lg text-white w-full max-w-sm"
          style={{
            borderImage: "linear-gradient(to right, #8a2be2, #4b0082) 1",
            borderWidth: "6px",
            borderStyle: "solid",
          }}
        >
          <h3 className="text-2xl font-bold mb-4 text-purple-300">
            CrackIIT Star Plan
          </h3>
          <p className="text-sm mb-6">
            Access our exclusive IITian mentorship program designed for serious JEE aspirants. Learn from the best IITians and NITians and achieve your dream!
          </p>

          <div className="flex justify-between items-center mb-4">
            <span className="text-3xl font-bold">₹39</span>
            <span className="text-sm">/2 days</span>
          </div>

          <ul className="space-y-2 mb-6 text-sm">
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-green-500 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Ask doubts from IITian mentors
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-green-500 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
               5 doubts can be asked            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-green-500 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Video & Chat solutions by IITian mentors 
            </li>

            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-green-500 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              2 days validity
            </li>
         

          <li className="flex items-center">
              <svg
                className="w-5 h-5 text-green-500 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              After payment you will receive the course & next steps on your email
            </li>

            </ul>
          <button
            onClick={handleBookNow}
            className="block w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-center transition duration-300"
          >
            Click to connect with IITian mentor @₹39
          </button>
        </div>
      </div>
    </div>
  );
};

export default Masterfeature;
